<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <b-img
                    :src="appLogoImageName"
                    alt="logo"
                    style="height: 80px; width: 150px;"
                />
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="4"
                class="auth-bg px-2 pb-lg-5 pt-2"
            >
                <div class="d-flex justify-content-end">
                    <language-dropdown />
                </div>
                <div class="d-flex align-items-center h-100">
                    <b-col
                        sm="8"
                        md="6"
                        lg="12"
                        class="px-xl-2 mx-auto"
                    >
                        <b-card-title
                            title-tag="h2"
                            class="font-weight-bold mb-1"
                        >
                            {{ $t('password.reset_password') }}
                        </b-card-title>

                        <!-- form -->
                        <validation-observer ref="loginValidation">
                            <b-form
                                class="auth-login-form mt-2"
                                @submit.prevent
                            >

                                <!-- Password One -->
                                <b-form-group
                                    :label="$t('general.password')"
                                    label-for="register-password"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="password"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="register-field1"
                                            v-model="userPassword"
                                            :state="errors.length > 0 ? false:null"
                                            name="register-password"
                                            placeholder="**************"
                                            type="password"
                                            autocomplete="off"
                                            :disabled="!linkValid"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <!-- Password Two -->
                                <b-form-group
                                    :label="this.$t('password.confirm_password')"
                                    label-for="register-confirm-password"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="confirm password"
                                        rules="required|min:4|confirmed:password"
                                    >
                                        <b-form-input
                                            id="register-confirm-field2"
                                            v-model="userPasswordConfirm"
                                            :state="errors.length > 0 ? false:null"
                                            name="register-confirm-password"
                                            placeholder="**************"
                                            type="password"
                                            autocomplete="new-password"
                                            :disabled="!linkValid"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <!-- submit buttons -->
                                <b-button
                                    type="submit"
                                    variant="primary"
                                    block
                                    @click="validationForm"
                                    :disabled="!linkValid"
                                >
                                    {{ $t('password.reset_password') }}
                                </b-button>

                                <div class="text-center" style="color: #ea5455; margin-top: 3px;" v-if="!linkValid">
                                    {{$t('password.link_expired')}}
                                </div>
                            </b-form>
                        </validation-observer>

                    </b-col>
                </div>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BLink, BFormGroup, BFormInput, BCardTitle, BImg, BForm, BButton
    } from 'bootstrap-vue'
    import { required, email } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import store from '@/store/index'
    import { $themeConfig } from '@themeConfig'
    import LanguageDropdown from '@/components/LanguageDropdown.vue'

    export default {
        components: {
            LanguageDropdown,
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            ValidationProvider,
            ValidationObserver
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                linkValid: true,
                userPassword: '',
                userPasswordConfirm: '',
                userName: '',
                userSurname: '',
                userEmail: '',
                passwordResetLink: '',
                rememberMe: false,
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                // validation rulesimport store from '@/store/index'
                required,
                email
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            }
        },
        methods: {
            validationForm() {
                this.$refs.loginValidation.validate().then(() => {
                    this.resetPassword()
                })
            },
            redirect() {
                this.$router.replace({name: 'login'})
            },
            async resetPassword() {

                const payload = {
                    password: this.userPassword
                }

                try {
                    await this.$http.post(`/api/client/v1/forgotten_password/reset/${  this.passwordResetLink}`, payload)

                    this.$printSuccess(this.$t('print.success.password_changed'))
                    setTimeout(this.redirect(), '2000')
                } catch (error) {
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.$store.state.app.showLoader = false
                }
            }

        },
        setup() {
            // App Name
            const { appLogoImageName } = $themeConfig.app
            return {
                appLogoImageName
            }
        },
        async mounted() {
            this.passwordResetLink = this.$route.params.link

            try {
                await this.$http.get(`/api/client/v1/forgotten_password/check_validation/${  this.passwordResetLink}`)
            } catch (error) {
                this.linkValid = false
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
